import { OssBank } from '../models/oss-bank.model';
import { OssPaymentSubject } from '../models/oss-payment-subject.model';
import { OssCosts } from '../models/oss-costs.model';
import { OssDepart } from '../models/oss-depart.model';
import { Material } from '../models/material.model';
import { PurchaseOrder } from '../models/purchase-order.model';
import { ExpenseItem } from '../models/expense.model';
import { BaseUser, Customer } from '../models/user-tln.model';
import { SponsorDepart } from '../models/department.model';
import { Location } from '../models/location.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_URL } from '../constants/api.constants';
import { Currency } from '../models/currency.model';
import { tap, map, flatMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Supplier } from '../models/supplier.model';
import { ControlDepart } from '../models/department.model';
import { UserTln } from '../models/user-tln.model';
import { Tax } from '../models/tax.model';
import { ServiceHelper } from '../helpers/service.helper';
import { OssApprover } from '../models/document-category.model';
import { EpoLoginService } from './epo-login.service';
import { Budget, BudgetRequest } from '../models/budget';
import { UnitOfMeasure } from '../models/unit-of-measure.model';
import * as moment from 'moment';
import * as _ from 'lodash';
import { getArrayItemsAdaptVersion } from '../../../app.const';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommonDataService {
  private currencies: Currency[];
  private ossDeparts: OssDepart[];
  private ossBanks: OssBank[];
  private ossPaymentSubjects: OssPaymentSubject[];
  private controlDepartments: ControlDepart[];
  private sponsorDepartments: SponsorDepart[];
  private taxTypesMap: Map<String, Tax[]> = new Map();

  // private approversCacheList: UserTln[] = [];
  // private approverFilter = new Set();

  // private usersCacheList: UserTln[] = [];
  // private usersOssCacheList: Map<string, UserTln[]> = new Map();
  private locationCacheList: Location[] = [];
  private materialCacheList: Material[] = [];
  private ossCostsCacheList: OssCosts[] = [];
  // private customersCacheList: BaseUser[] = [];
  // private customerAdvanceLimitList: BaseUser[] = [];
  // private userFilter = new Set();
  // private customerFilter = new Set();
  // private customerAdvanceLimit = new Set();
  private ossCostsFilter = new Set();
  private locationFilter = new Set();
  private materialFilter = new Set();
  // private codeFilter = new Set();
  private taxFilter = new Set();

  constructor(
    private http: HttpClient,
    private loginService: EpoLoginService
  ) {}

  getCurrency() {
    if (this.currencies) {
      return of(this.currencies);
    }
    return this.http.get<Currency[]>(API_URL.COMMON_DATA.GET_ALL_CURRENCY).pipe(
      tap((currencies) => {
        if (currencies?.length == 0) {
          currencies = [
            {
              name: 'VND',
              code: 'VND',
              label: 'VND',
              value: 'VND',
              exchangeRate: '1',
            } as Currency,
          ];
        }

        this.currencies = currencies.map((r: any) => {
          let newName = `${r.code || ''} - ${r.name || ''}`;
          r.exchangeRate = r.exchangeRate || 0;
          r.code = r.code || '';
          r.name = newName.trim() === '-' ? `ID ${r.id}` : newName;
          r.label = newName.trim() === '-' ? `ID ${r.id}` : newName;
          //   {
          //     "id": 34,
          //     "status": 1,
          //     "createdAt": 1717320390529,
          //     "modifiedAt": 1717320390529,
          //     "createdBy": "alan@RioSolution.onmicrosoft.com",
          //     "modifiedBy": "alan@RioSolution.onmicrosoft.com",
          //     "exchangeRate": null,
          //     "code": null,
          //     "name": null
          // },
          return r as Currency;
        }) as Currency[];
      })
    );
  }
  async getCurrencyV2() {
    try {
      let currencies = await this.http.get<Currency[]>(API_URL.COMMON_DATA.GET_ALL_CURRENCY).toPromise();
      currencies = currencies?.map((r: any) => {
        let label = r.name;
        let value = r.code;
        if (label && value && label != value) {
          label += ` - ${value}`;
        }
        r.exchangeRate = r.exchangeRate || 0;
        r.value = String(value);
        r.label = label;

        return r as Currency;
      });
      return currencies?.length == 0
        ? [
            {
              name: 'VND',
              code: 'VND',
              label: 'VND',
              value: 'VND',
              exchangeRate: '1',
            } as Currency,
          ]
        : currencies;
    } catch (error) {
      console.error(`can not get currency code v2`, error);
      return [
        {
          name: 'VND',
          code: 'VND',
          label: 'VND',
          value: 'VND',
          exchangeRate: '1',
        } as Currency,
      ];
    }
  }

  getOssDepart() {
    if (this.ossDeparts) {
      return of(this.ossDeparts);
    }
    return this.http.get<OssDepart[]>(API_URL.COMMON_DATA.GET_ALL_OSS_DEPART).pipe(
      tap((ossDeparts) => {
        this.ossDeparts = ossDeparts;
      })
    );
  }

  getPaymentSubjects() {
    if (this.ossPaymentSubjects) {
      return of(this.ossPaymentSubjects);
    }
    return this.http.get<OssPaymentSubject[]>(API_URL.COMMON_DATA.GET_ALL_OSS_PAYMENT_SUBJECT).pipe(
      tap((ossPaymentSubjects) => {
        this.ossPaymentSubjects = ossPaymentSubjects;
      })
    );
  }

  searchByOssCosts(name: string) {
    if (this.ossCostsFilter.has(name)) {
      return of(this.ossCostsCacheList);
    }
    return this.http.get<OssCosts[]>(`${API_URL.COMMON_DATA.GET_ALL_OSS_COSTS}?query=${name}`).pipe(
      tap((ossCost) => {
        this.ossCostsCacheList = [...new Map([...this.ossCostsCacheList, ...ossCost].map((item) => [item.codeId, item])).values()];
        this.ossCostsFilter.add(name);
      })
    );
  }

  async getTaxTypesForCO() {
    let url = API_URL.COMMON_DATA.GET_ALL_TAX_TYPE + `?applyFor=CO`;
    return this.http.get<Tax[]>(url).toPromise(); //.pipe(
    //   tap((taxTypes) => {
    //     this.taxTypesMap.set(applyFor, taxTypes);
    //     this.taxFilter.add(applyFor);
    //   })
    // );
  }

  getTaxTypes(applyFor: string = '') {
    if (this.taxFilter.has(applyFor)) {
      return of(this.taxTypesMap.get(applyFor));
    }
    let url = API_URL.COMMON_DATA.GET_ALL_TAX_TYPE;
    if (ServiceHelper.isObjectNotEmpty(applyFor)) {
      url += `?applyFor=${applyFor}`;
    }
    return this.http.get<Tax[]>(url).pipe(
      tap((taxTypes) => {
        this.taxTypesMap.set(applyFor, taxTypes);
        this.taxFilter.add(applyFor);
      })
    );
  }

  getOssBank() {
    if (this.ossBanks) {
      return of(this.ossBanks);
    }
    return this.http.get<OssBank[]>(API_URL.COMMON_DATA.GET_ALL_OSS_BANK).pipe(
      tap((ossBanks) => {
        this.ossBanks = ossBanks;
      })
    );
  }

  getSupplierByName(name?: string) {
    return this.http.get<Supplier[]>(`${API_URL.SUPPLIER.ROOT}`, {
      params: {
        name: name ? name : '',
      },
    });
  }

  getControlDepartments() {
    if (this.controlDepartments) {
      return of(this.controlDepartments);
    }
    return this.http.get<ControlDepart[]>(API_URL.COMMON_DATA.GET_ALL_CONTROL_DEPARTMENT).pipe(
      tap((departments) => {
        this.controlDepartments = departments.map((r: any) => {
          r.name = r.name || r.departmentName;
          r.departmentCode = r.departmentCode || r.code;
          return r;
        }) as ControlDepart[];
      })
    );
  }

  async getDepartmentsV2(params: any = null) {
    return await this.http
      .get<ControlDepart[]>(`${environment.apiBaseUrl}${environment.defaultVersion}/dgs/v2/department`, {
        params: {
          page: 0,
          limit: 1000,
          ...(typeof params == 'string' ? { keyword: params } : params ? { ...params } : {}),
        },
      })
      .toPromise();
  }

  async getManagementAccountV2(params: any = null) {
    return await this.http
      .get(`${environment.apiBaseUrl}${environment.defaultVersion}/dgs/v2/management-account`, {
        params: {
          page: 0,
          limit: 1000,
          ...(typeof params == 'string' ? { keyword: params } : params ? { ...params } : {}),
        },
      })
      .toPromise();
  }

  async getControlDepartmentsV2(params: any = null) {
    return await this.http
      .get<ControlDepart[]>(API_URL.COMMON_DATA.GET_ALL_CONTROL_DEPARTMENT_V2, {
        params: {
          page: 0,
          limit: 500,
          ...(params || {}),
        },
      })
      .toPromise();
  }

  getSponsorDepartments() {
    if (this.sponsorDepartments) {
      return of(this.sponsorDepartments);
    }
    return this.http.get<ControlDepart[]>(API_URL.COMMON_DATA.GET_ALL_SPONSOR_DEPARTMENT).pipe(
      tap((departments) => {
        this.sponsorDepartments = departments;
      })
    );
  }
  async getSponsorDepartmentsV2() {
    return this.http
      .get<ControlDepart[]>(API_URL.COMMON_DATA.GET_ALL_SPONSOR_DEPARTMENT_V2, {
        params: { limit: 500 },
      })
      .toPromise();
  }

  searchByOssApprover(name?: string, categoryId?: string, filterByRole?: string) {
    const url = `${API_URL.COMMON_DATA.SEARCH_OSS_APPROVER_BY_NAME}?name=${name}&categoryId=${categoryId}&filterByRole=${filterByRole}`;
    return this.http.get<OssApprover[]>(url).pipe(
      map((approvers) => {
        return approvers.filter((approver) => approver.consultantId !== this.loginService.getCurrentUserId());
      })
    );
  }

  getApprovers(name?: string, level?: string) {
    const params: any = {};
    if (ServiceHelper.isObjectNotEmpty(level)) {
      params.level = level;
    }
    if (ServiceHelper.isObjectNotEmpty(name)) {
      params.name = name;
    }
    params.ignoreCurrentUser = true;

    return this.http.get<UserTln[]>(API_URL.COMMON_DATA.SEARCH_APPROVER_BY_NAME, { params: params });
  }

  getApproversByControlDepartV2({ keyword, controlDepartmentId, controlDepartIds }: { keyword?: string; controlDepartmentId?: string; controlDepartIds?: string[] }) {
    const oldUrl = API_URL.COMMON_DATA.SEARCH_APPROVER_BY_NAME_LEVEL;
    const url = `${environment.apiBaseUrl}${environment.defaultVersion}/approver/control-departs`;
    if (!controlDepartIds && controlDepartmentId) {
      controlDepartIds = [controlDepartmentId];
    }
    controlDepartIds = _.uniq(controlDepartIds);
    return (
      this.http
        // .get<UserTln[]>(API_URL.COMMON_DATA.SEARCH_APPROVER_BY_NAME_LEVEL, {
        .get<UserTln[]>(url, {
          params: {
            ...(keyword ? { keyword } : {}),
            ...(controlDepartmentId ? { controlDepartmentId } : {}),
            ...(controlDepartIds?.length > 0 ? { controlDepartIds: controlDepartIds.join(',') } : { controlDepartIds: '' }),
          },
        })
        .toPromise()
    );
  }

  getApproversByNameLevel(name?: string, level?: string, controlDepart?: string) {
    const params: any = {};
    if (ServiceHelper.isObjectNotEmpty(level)) {
      params.level = level;
    }
    if (ServiceHelper.isObjectNotEmpty(name)) {
      params.name = name;
    }
    if (ServiceHelper.isObjectNotEmpty(controlDepart)) {
      params.controlDepart = controlDepart;
    }
    params.ignoreCurrentUser = true;

    return this.http.get<UserTln[]>(API_URL.COMMON_DATA.SEARCH_APPROVER_BY_NAME_LEVEL, { params: params });
  }

  searchUserByName(keyword: string | string[]) {
    const isKeywordArray = Array.isArray(keyword);
    return this.http.get<UserTln[]>(`${API_URL.COMMON_DATA.SEARCH_USER_BY_NAME}?keyword=${keyword || ''}&multiple=${isKeywordArray}`);
  }

  searchUserByNameV2(keyword: string | string[]) {
    const isKeywordArray = Array.isArray(keyword);
    return this.http.get<UserTln[]>(`${API_URL.COMMON_DATA.SEARCH_USER_BY_NAME}?keyword=${keyword || ''}&multiple=${isKeywordArray}`).toPromise();
  }

  searchUserOssByName(keyword: string, customerId: string = '') {
    return this.http.get<any[]>(`${API_URL.COMMON_DATA.SEARCH_USER_OSS_BY_NAME}?keyword=${keyword || ''}&customerId=${customerId}`);
  }

  /**
   *
   * @param name Combination between Customer and UserTln
   */
  searchByCustomerInExpenseForm(name: string) {
    return this.http.get<BaseUser[]>(`${API_URL.COMMON_DATA.SEARCH_CUSTOMER_BY_NAME}?name=${name}`).pipe(
      flatMap((customer) => {
        if (customer.length < 10) {
          return this.http.get<UserTln[]>(`${API_URL.COMMON_DATA.SEARCH_USER_BY_NAME}?keyword=${name}`).pipe(map((user) => customer.concat(user)));
        } else {
          return of(customer);
        }
      })
    );
  }

  searchCustomer(name: string) {
    return this.http.get<Customer[]>(`${API_URL.COMMON_DATA.SEARCH_CUSTOMER_BY_NAME}?name=${name}`);
  }

  searchBudgetByCode(code: string) {
    return this.http.get<UserTln[]>(`${API_URL.COMMON_DATA.SEARCH_BUDGET_BY_CODE}?code=${code}`);
  }

  searchByOssProduct(code: string) {
    return this.http.get<Material[]>(`${API_URL.COMMON_DATA.SEARCH_MATERIAL_BY_CODE}?name=${code}&linkOss=true`);
  }

  searchByAllProduct(code: string) {
    if (this.materialFilter.has(code)) {
      return of(this.materialCacheList);
    }
    return this.http.get<Material[]>(`${API_URL.COMMON_DATA.SEARCH_MATERIAL_BY_CODE}?name=${code}`).pipe(
      tap((materials) => {
        this.materialCacheList = [...new Map([...this.materialCacheList, ...materials].map((item) => [item.code, item])).values()];
        this.materialFilter.add(code);
      })
    );
  }

  searchExpenseItemsBy(code: string) {
    return this.http.get<ExpenseItem[]>(`${API_URL.COMMON_DATA.SEARCH_EXPENSE_ITEM_BY_CODE}?code=${code}`);
  }

  searchAccountantDepartBy(query: string, location?: string, division?: string) {
    let url = `${API_URL.COMMON_DATA.SEARCH_ACCOUNTANT_DEPART_BY_CODE}?query=${query}`;
    if (ServiceHelper.isObjectNotEmpty(location)) {
      url += `&location=${location}`;
    }
    if (ServiceHelper.isObjectNotEmpty(division)) {
      url += `&division=${division}`;
    }
    return this.http.get<ExpenseItem[]>(url);
  }

  async searchAccountantDepartApi({ query, location, division, page, limit, keyword }: any) {
    try {
      let url = `${API_URL.COMMON_DATA.SEARCH_ACCOUNTANT_DEPART_V2}`;
      let rs: any = await this.http
        .get<ExpenseItem[]>(url, {
          params: {
            ...(page || page === 0 ? { page } : { page: 0 }),
            ...(limit ? { limit } : { limit: 20 }),
            ...(keyword ? { keyword } : {}),
            ...(location ? { location } : {}),
            ...(division ? { division } : {}),
            ...(query ? { query } : {}),
          },
        })
        .toPromise();
      rs.data.items = rs.data.items.map((r) => {
        // r.label = `${r.code || ''}${r.code ? ' - ' : ''}${r.teamName}`;
        // r.value = `${r.code || ''}`;
        return r;
      });
      return rs;
    } catch (error) {
      console.error(error);
      return {
        data: {
          items: [],
        },
      };
    }
  }

  searchAdvancePaymentOssSupport(code: string, clientName?: string) {
    return this.http.get<any[]>(`${API_URL.COMMON_DATA.SEARCH_ADVANCE_PAYMENT_OSS}?code=${code}&clientId=${clientName}`);
  }

  searchBudgetRequestByCode(code: string) {
    return this.http.get<BudgetRequest[]>(`${API_URL.COMMON_DATA.SEARCH_BUDGET_REQUEST_BY_CODE}?code=${code}`);
  }

  searchBudgetByCodeOrDes(prefix: string, aggregate: boolean = false, controlDepart: string = '', sponsorDepart: string = ''): Observable<Budget[]> {
    let params = new HttpParams();
    if (ServiceHelper.isObjectNotEmpty(prefix)) {
      params = params.set('prefix', prefix);
    }
    params = params.set('aggregate', aggregate.toString());
    if (ServiceHelper.isObjectNotEmpty(controlDepart)) {
      params = params.set('controlDepart', controlDepart);
    }
    if (ServiceHelper.isObjectNotEmpty(sponsorDepart)) {
      params = params.set('sponsorDepart', sponsorDepart);
    }
    return this.http
      .get(`${API_URL.BUDGET_CONTROL.BUDGET_ROOT}`, {
        params: params,
      })
      .pipe(map((response) => (response as any).content));
  }

  async searchBudgetCodeV2({ keyword, controlDepartmentId }: any): Promise<Budget[]> {
    // let params = new HttpParams();
    // if (ServiceHelper.isObjectNotEmpty(prefix)) {
    //   params = params.set('prefix', prefix);
    // }
    // params = params.set('aggregate', aggregate.toString());
    // if (ServiceHelper.isObjectNotEmpty(controlDepart)) {
    //   params = params.set('controlDepart', controlDepart);
    // }
    // if (ServiceHelper.isObjectNotEmpty(sponsorDepart)) {
    //   params = params.set('sponsorDepart', sponsorDepart);
    // }
    if (!controlDepartmentId) {
      console.error(`searchBudgetCodeV2 missing controlDepartmentId`);
      return [];
    }
    const url = environment.apiBaseUrl + environment.defaultVersion + `/dgs/v2/budget/control-depart/${controlDepartmentId}`;
    let result: any = await this.http
      .get(url, {
        params: {
          ...(keyword ? { keyword } : {}),
        },
      })
      .toPromise();
    // let items = getArrayItemsAdaptVersion({
    //   result,
    //   mapOptions: true,
    //   labelKey: ['code'],
    //   valueKey: ['id'],
    // });
    // console.log(`items`, items);
    return result;
  }

  async searchBudgetByCodeOrDes_v2(prefix: string, aggregate: boolean = false, controlDepart: string = '', sponsorDepart: string = ''): Promise<Budget[]> {
    let params = new HttpParams();
    if (ServiceHelper.isObjectNotEmpty(prefix)) {
      params = params.set('prefix', prefix);
    }
    params = params.set('aggregate', aggregate.toString());
    if (ServiceHelper.isObjectNotEmpty(controlDepart)) {
      params = params.set('controlDepart', controlDepart);
    }
    if (ServiceHelper.isObjectNotEmpty(sponsorDepart)) {
      params = params.set('sponsorDepart', sponsorDepart);
    }
    let result: any = await this.http
      .get(`${API_URL.BUDGET_CONTROL.BUDGET_ROOT}`, {
        params: params,
      })
      .toPromise();
    return getArrayItemsAdaptVersion({
      result,
      mapOptions: true,
      labelKey: ['budgetCode', 'code', 'description'],
      valueKey: ['budgetCode', 'code'],
    });
  }

  searchPoByPoId(purchaseOrderId: string) {
    return this.http.get<PurchaseOrder[]>(`${API_URL.COMMON_DATA.SEARCH_PO_BY_ID}?code=${purchaseOrderId ? purchaseOrderId : ''}`);
  }

  searchPoAvailableForCO({ keyword, page, limit, status, ...rest }: any) {
    page = page || 0;
    limit = limit || 10;
    const url = environment.apiBaseUrl + environment.defaultVersion + `/dgs/v2/po/avail`;
    return this.http
      .get(url, {
        params: {
          ...(rest || {}),
          ...(keyword ? { keyword } : {}),
          ...(page || page === 0 ? { page } : {}),
          ...(limit ? { limit } : {}),
          ...(status ? { status } : {}),
        },
      })
      .toPromise();
  }

  getAdminPermission(): any {
    return this.http.get(API_URL.COMMON_DATA.GET_AMIN_PERMISSION) as any;
  }

  getCurrencyByCode(code: string) {
    return this.http.get<Currency>(`${API_URL.COMMON_DATA.GET_ALL_CURRENCY}/${code}`);
  }

  getExpenseItemByBudgetCode(code: string, effectYear: number) {
    let params = new HttpParams();
    if (effectYear != null) {
      params = params.set('effectYear', effectYear.toString());
    }
    return this.http.get<ExpenseItem>(`${API_URL.COMMON_DATA.GET_EXPENSE_ITEM_BY_BUDGET_CODE}/${code}`, {
      params: params,
      observe: 'response',
    });
  }

  getExpenseItemForChargeable(targetType: string) {
    return this.http.get<ExpenseItem>(`${API_URL.COMMON_DATA.GET_EXPENSE_ITEM_BY_TYPE}/CHARGEABLE/target-type/${targetType}`, { observe: 'response' });
  }

  searchLocation(name: string) {
    if (this.locationFilter.has(name)) {
      return of(this.locationCacheList);
    }
    return this.http.get<Location[]>(`${API_URL.COMMON_DATA.GET_LOCATION}?name=${name}`).pipe(
      tap((localtions) => {
        this.locationCacheList = [...new Map([...this.locationCacheList, ...localtions].map((item) => [item.code, item])).values()];
        this.locationFilter.add(name);
      })
    );
  }

  searchUnitOfMeasure() {
    return this.http.get<UnitOfMeasure[]>(`${API_URL.COMMON_DATA.GET_UNIT_OF_MEASURE}`);
  }

  getBudgetItemByUserId(userId: string) {
    return this.http.get<ExpenseItem>(`${API_URL.COMMON_DATA.GET_EXPENSE_ITEM_BY_USER_ID}/${userId}`, { observe: 'response' });
  }

  findBeneficiaryOfDepartment(departmentId: string) {
    return this.http.get<UserTln>(`${API_URL.COMMON_DATA.GET_BENEFICIARY}?departmentId=${departmentId}`);
  }

  findByUserIdIn(customerId: string[] = []) {
    return this.http.get<any[]>(`${API_URL.COMMON_DATA.SEARCH_USER_OSS_BY_NAME}/${customerId.join(',')}`);
  }
  parseFromToDate(params = {}, key = 'fromToDate') {
    key = key || 'fromToDate';
    let fromDate = null;
    let toDate = null;
    if (params[key] && params[key].length == 2) {
      fromDate = moment(params[key][0]).format('YYYY-MM-DD');
      toDate = moment(params[key][1]).format('YYYY-MM-DD');
    }
    return {
      fromDate,
      toDate,
    };
  }
  async getActivityLogEnumCMDApi({}) {
    try {
      let url = `${API_URL.COMMON_DATA.GET_ACTIVITY_LOG_ENUM_CMD}`;
      let rs: any = await this.http
        .get(url, {
          params: {},
        })
        .toPromise();
      rs.data.items = rs.data.items.map((r) => {
        let t = String(r);
        // r.label = String(t);
        // r.value = String(t);
        return {
          label: t,
          value: t,
          key: t,
        };
      });
      return getArrayItemsAdaptVersion({
        result: rs,
        mapOptions: true,
        labelKey: ['value'],
        valueKey: ['key'],
      });
    } catch (error) {
      console.error(error);
      return {
        data: {
          items: [],
        },
      };
    }
  }
  async getActivityLogApi({ page, limit, keyword, query, cmd, location, objectId, objectName, createdAtFrom, createdAtTo }) {
    try {
      let url = `${API_URL.COMMON_DATA.GET_ACTIVITY_LOG}`;
      let rs: any = await this.http
        .get(url, {
          params: {
            ...(page || page === 0 ? { page } : { page: 0 }),
            ...(limit ? { limit } : { limit: 20 }),
            ...(keyword ? { keyword } : {}),
            ...(location ? { location } : {}),
            ...(query ? { query } : {}),
            ...(cmd ? { cmd } : {}),
            ...(objectId ? { objectId } : {}),
            ...(objectName ? { objectName } : {}),
            ...(createdAtFrom && createdAtTo ? { createdAtFrom, createdAtTo } : {}),
          },
        })
        .toPromise();
      // rs.data.items = rs.data.items.map((r) => {
      //   // r.label = `${r.code || ''}${r.code ? ' - ' : ''}${r.teamName}`;
      //   // r.value = `${r.code || ''}`;
      //   return r;
      // });
      return rs;
    } catch (error) {
      console.error(error);
      return {
        data: {
          items: [],
        },
      };
    }
  }

  async extractEinvoicePdfApi({ formData }) {
    try {
      // let url = environment.apiBaseUrl + '/v1/api/dgs/v2/hub/einvoice/transactions/extract-data';
      let url = environment.apiBaseUrl + environment.defaultVersion + `/dgs/v2/hub/einvoice/transactions/extract-data`;
      return this.http.post(url, formData).toPromise();
      // rs.data.items = rs.data.items.map((r) => {
      //   // r.label = `${r.code || ''}${r.code ? ' - ' : ''}${r.teamName}`;
      //   // r.value = `${r.code || ''}`;
      //   return r;
      // });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  flattenObject(ob: Record<string, any>): Record<string, any> {
    var toReturn = {};

    for (var i in ob) {
      if (!ob.hasOwnProperty(i)) continue;

      if (typeof ob[i] == 'object' && ob[i] !== null) {
        var flatObject = this.flattenObject(ob[i]);
        for (var x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;

          toReturn[i + '.' + x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }
    return toReturn;
  }
  unflattenObject(data: Record<string, any>): Record<string, any> {
    const result = {};

    for (let i in data) {
      const keys = i.split('.');
      keys.reduce((acc, key, index) => {
        return (acc[key] = index === keys.length - 1 ? data[i] : acc[key] || (isNaN(keys[(index + 1) as any] as any) ? {} : []));
      }, result);
    }

    return result;
  }
}
