import * as _ from 'lodash';
export const LANG_VI = 'vi';
export const LANG_EN = 'en';
export const PAGE_IDS = {
  EINVOICE: 'EINVOICE',
  EINVOICE_V2: 'EINVOICE-V2',
  MASTER_DATA: 'MASTER_DATA',
  BUDGET_CONFIG: 'BUDGET_CONFIG',
};
export const ExcelFileTypes = [
  'text/csv',
  'application/csv',
  'text/comma-separated-values',
  'application/csv',
  'application/excel',
  'application/vnd.msexcel', // 'text/anytext',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
export enum INVOICE_STATUS_ENUM {
  DRAFT = 'DRAFT',
  WAITING = 'WAITING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CLOSED = 'CLOSED',
  PAID = 'PAID',
  RECORDED = 'RECORDED',
  HALF_DONE = 'HALF_DONE',
  BILLED = 'BILLED',
}
export enum INVOICE_TYPE_ENUM {
  EINVOICE = 'EINVOICE',
  OTHERS = 'OTHERS',
}
export const XMLFileTypes = ['text/xml', 'application/xml'];
export const PDFFileTypes = ['application/pdf'];
export const ImageFileTypes = ['png', 'jpge', 'jpg', 'image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp'];
export const blockUiRequestFilterOut = [
  {
    method: 'GET',
    url: /eclaim-service\/v1\/api/,
    //eclaim-service/v1/api/dgs/v2
  },
  {
    method: 'GET',
    url: /eclaim-service\/v1\/api\/dgs\/v2/,
  },
];

export const getArrayItemsAdaptVersion = ({
  mergeExistedItems,
  result,
  mapOptions = null,
  labelKey = null,
  valueKey = null,
}: {
  mergeExistedItems?: any; //merge voi item đã tồn tại cho mode edit
  result?: any;
  mapOptions?: boolean;
  labelKey?: string[] | string | any;
  valueKey?: string[] | string | any;
}) => {
  mapOptions = mapOptions || null;
  labelKey = labelKey || 'label'; // 'budgetName'
  valueKey = valueKey || 'value'; // 'id
  let items = Array.isArray(result) && result.length > 0 ? result : [];
  if (items.length == 0) {
    if (Array.isArray(result?.content) && result?.content?.length > 0) {
      items = result?.content;
    }
  }
  if (items.length == 0) {
    if (Array.isArray(result?.data?.items) && result?.data?.items?.length > 0) {
      items = result?.data?.items;
    }
  }
  if (!mapOptions) return items;
  if (mergeExistedItems?.length > 0) {
    for (let item of mergeExistedItems) {
      let plainObj: any = {};
      for (let pro in item) {
        if (typeof item[pro] != 'function') {
          plainObj[pro] = item[pro];
        }
      }
      if (plainObj?.id) {
        let foundIdx = _.findIndex(items, { id: plainObj?.id });
        if (foundIdx == -1) {
          items = [...items, plainObj];
        }
      }
    }
    console.log(`after mergeExistedItems list items items=`, items);
  }

  return items.map((r) => {
    let labelValue = ''; //concat array key
    if (Array.isArray(labelKey) && labelKey.length > 0) {
      for (let k of labelKey) {
        let hasSperateChar = !!labelValue;
        let sperateChar = `${r[k] ? ' - ' : ''}`;
        labelValue += `${hasSperateChar ? sperateChar : ''}${r[k] || ''}`;
      }
    }
    let valueValue = '';
    if (Array.isArray(valueKey) && valueKey.length > 0) {
      for (let k of valueKey) {
        if (r[k]) {
          valueValue = r[k];
          break;
        }
      }
    }
    return {
      ...r,
      label: labelValue || r[labelKey] || r.name || r.code,
      value: valueValue || r[valueKey] || r.code || r.id,
    };
  });
};

export const formatSelectOptionsItems = getArrayItemsAdaptVersion;

export const getApiErrorMessage = (response) => {
  let message = response?.error?.message || response?.error?.title || response?.error?.detail;
  if (!message && response?.error) {
    message = JSON.stringify(response?.error);
  }
  if (!message && response?.message) {
    message = response?.message;
  }
  return message || JSON.stringify(response);
};
export const getApiErrorKeyMessage = (response) => {
  let message = response?.error?.message || '';
  let key = response?.error?.errorKey || '';
  if (message) {
    // neu BE tra ve message => uu tien show message.
    return {
      // key,
      message,
    };
  }
  if (message == key) {
    return {
      key: response?.error?.errorKey, //need translate this key
      // message,
    };
  }
  // if(message && String(message).includes('_')){ // message is not friendly message => need translate
  //   return {
  //     key,
  //   }
  // }
  // if(message){
  //   return {
  //     message,
  //   }
  // }
  return {
    message,
    key,
  };
};

export const isArrayHasData = (arr) => {
  return Array.isArray(arr) && arr.length > 0 && _.isEmpty(arr) === false;
};
export const isEmptyArray = (v) => {
  return !Array.isArray(v) || v.length == 0 || _.isEmpty(v);
};
