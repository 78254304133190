import Swal from 'sweetalert2';
import * as _ from 'lodash';
import { APP_CONSTANTS, AUTH_CONSTANTS } from '../constants/app.constants';
import { DatePipe } from '@angular/common';
import { LANG_EN, LANG_VI, getApiErrorKeyMessage } from '../../../app.const';

export class ServiceHelper {
  static datePipe = new DatePipe('en');

  public static hasRequiredRole(currentUserRoles: string[], requiredUserRoles: string[]): boolean {
    if (currentUserRoles == null) {
      return false;
    }
    if (ServiceHelper.isObjectEmpty(requiredUserRoles)) {
      return true;
    }
    for (let i = 0; i < requiredUserRoles.length; i++) {
      for (let j = 0; j < currentUserRoles.length; j++) {
        if (requiredUserRoles[i].toLowerCase() === currentUserRoles[j].toLowerCase()) {
          return true;
        }
      }
    }
    return false;
  }

  public static currentUserIs(expected: string): boolean {
    return expected === localStorage.getItem(AUTH_CONSTANTS.EPO_USER_ID);
  }

  public static formatDate(date, format = 'mediumDate') {
    return date ? this.datePipe.transform(date, format) : '';
  }

  public static isObjectEmpty(value: any): boolean {
    if (value == null || value === undefined) {
      return true;
    }
    if (Array.isArray(value) && value.length) {
      return value.length === 0;
    } else if (typeof value === 'object') {
      return (Object.keys(value).length === 0 && value.constructor === Object) || _.isEmpty(value);
    } else if (typeof value === 'string') {
      return value.length === 0;
    } else if (typeof value === 'number') {
      return value === 0;
    } else if (!value) {
      return true;
    }
    return false;
  }

  public static isObjectNotEmpty(value: any): boolean {
    return !ServiceHelper.isObjectEmpty(value);
  }

  public static confirmPopup(title: string = '', text: string, confirmButtonText: string = 'Xác nhận', cancelButtonText: string = 'Hủy'): Promise<any> {
    let lang = localStorage.getItem(APP_CONSTANTS.LANGUAGE) || LANG_VI;
    confirmButtonText = lang === LANG_EN ? 'Confirm' : confirmButtonText;
    cancelButtonText = lang === LANG_EN ? 'Close' : cancelButtonText;
    return Swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: ServiceHelper.isObjectNotEmpty(cancelButtonText),
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
    });
  }

  public static confirmPopupI18n({ lang = LANG_VI, title = '', text = '', confirmButtonText = 'Xác nhận', cancelButtonText = 'Hủy' }: any): Promise<any> {
    confirmButtonText = lang === LANG_EN ? 'Confirm' : 'Xác nhận';
    cancelButtonText = lang === LANG_EN ? 'Cancel' : 'Hủy';
    return Swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: ServiceHelper.isObjectNotEmpty(cancelButtonText),
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
    });
  }

  public static errorPopup(text: string, confirmButtonText: string = 'Đóng'): Promise<any> {
    let lang = localStorage.getItem(APP_CONSTANTS.LANGUAGE) || LANG_VI;
    confirmButtonText = lang === LANG_EN ? 'Close' : confirmButtonText;
    return Swal.fire({
      text: text,
      icon: 'error',
      confirmButtonText: confirmButtonText,
    });
  }

  public static warningPopup(text: string, confirmButtonText: string = 'Đóng'): Promise<any> {
    let lang = localStorage.getItem(APP_CONSTANTS.LANGUAGE) || LANG_VI;
    confirmButtonText = lang === LANG_EN ? 'OK' : confirmButtonText;
    return Swal.fire({
      text: text,
      icon: 'warning',
      confirmButtonText: confirmButtonText,
    });
  }

  public static infoPopup(text: string, confirmButtonText: string): Promise<any> {
    return Swal.fire({
      text: text,
      icon: 'info',
      confirmButtonText: confirmButtonText,
    });
  }

  public static showSuccessAlert(title: string, content: string, confirmButtonText: string) {
    return Swal.fire({
      title: title,
      text: content,
      icon: 'success',
      confirmButtonText: confirmButtonText,
    });
  }

  public static showConfirmationAlertWithReason(title: string, content: string) {
    let lang = localStorage.getItem(APP_CONSTANTS.LANGUAGE) || LANG_VI;
    let confirmButtonText = lang === LANG_EN ? 'Confirm' : 'Đồng ý';
    let cancelButtonText = lang === LANG_EN ? 'Cancel' : 'Hủy';
    return Swal.fire({
      title: title,
      text: content,
      input: 'textarea',
      inputPlaceholder: 'Nội dung...',
      inputAttributes: {
        'aria-label': 'Nội dung',
      },
      showCancelButton: true,
      confirmButtonText: confirmButtonText || 'Đồng ý',
      cancelButtonText: cancelButtonText || 'Hủy bỏ',
    });
  }

  public static expandTextAreaOnKeyPress(event) {
    const text = event.target as HTMLTextAreaElement;
    const maxHeight = parseInt(text.style.maxHeight, 10);
    text.style.height = '0px';
    text.style.height = `${text.scrollHeight > maxHeight ? maxHeight : text.scrollHeight}px`;
  }

  public static numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  public static roundMoney(value: number, currencyCode: string = 'VND'): number {
    if (currencyCode === 'VND' || !currencyCode) {
      return Math.round(value);
    }
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }

  public static isAllowShowBEMessage(response): boolean {
    return [404, 400, 500].includes(response?.status);
  }
  public static getApiErrorKeyMessage(e) {
    return getApiErrorKeyMessage(e);
  }
}
