import { EpoLoginService } from '../../../common/services/epo-login.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NB_AUTH_OPTIONS, NbAuthSocialLink } from '../../auth.options';
import { getDeepFromObject, isLocalhost } from '../../helpers';

import { NbAuthService } from '../../services/auth.service';
import { NbAuthResult } from '../../services/auth-result';
import { NbGlobalLogicalPosition, NbToastrService } from '@nebular/theme';
import { ServiceHelper } from '../../../common/helpers/service.helper';
import { AppLanguageService } from '../../../common/services/app-language.service';
import { environment } from '../../../../../environments/environment';
import { NbTokenService } from '../../public_api';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'nb-login-callback',
  templateUrl: './logincallback.component.html',
  styleUrls: ['./logincallback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NbLoginCallbackComponent implements OnInit {
  // redirectDelay: number = 0;
  // showMessages: any = {};
  // strategy: string = '';

  // errors: string[] = [];
  // messages: string[] = [];
  // user: any = {
  //   username: isLocalhost() ? 'admin.epo@talentnet.vn' : '',
  //   password: isLocalhost() ? 'talentnet@123' : '',
  // };
  submitted: boolean = false;
  // socialLinks: NbAuthSocialLink[] = [];
  // rememberMe = false;
  loginFailed = false;
  // labels: any = {};
  error;
  token;

  constructor(
    protected service: NbAuthService,
    protected epoLoginService: EpoLoginService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    private cd: ChangeDetectorRef,
    private appLanguageService: AppLanguageService,
    // private EpoLoginService: EpoLoginService,
    protected router: Router,
    private ActivatedRoute: ActivatedRoute,
    private nbTokenService: NbTokenService,
    private nbAuthService: NbAuthService,
    private nzMessageService: NzMessageService
  ) {
    // this.redirectDelay = this.getConfigValue('forms.login.redirectDelay');
    // this.showMessages = this.getConfigValue('forms.login.showMessages');
    // this.strategy = this.getConfigValue('forms.login.strategy');
    // this.socialLinks = this.getConfigValue('forms.login.socialLinks');
    // this.rememberMe = this.getConfigValue('forms.login.rememberMe');
    this.appLanguageService.use(this.appLanguageService.getLanguage());
    this.ActivatedRoute.queryParams.subscribe(async (params) => {
      this.token = params['token'];
      this.error = params['error'];
      if (this.token) {
        await this.checkToken();
      } else {
        this.error = 'Missing token. Invalid request';
      }
      this.cd.markForCheck();
    });
  }

  loadLabelsI18n() {
    // this.labels = {
    //   // dontHaveAccount: this.appLanguageService.instant(),
    // };
  }
  ngOnInit() {}

  clickToLogin() {
    this.router.navigateByUrl('/auth/login');
  }

  async checkToken() {
    try {
      const module = 'login';
      let result = await this.epoLoginService.checkTokenIsValid(this.token);
      let authResult = new NbAuthResult(true, result, this.getOption(`${module}.redirect.success`), [], [], this.token);
      // console.log(`sso login result`, result);
      // console.log(`sso login authResult`, authResult);

      let body = authResult.getResponse().body || authResult.getResponse();
      body.data.token = body.data.token || this.token;
      // console.log(`sso login body`, body);
      this.epoLoginService.setLoginDataResponse(body);
      await this.nbTokenService.set(this.token).toPromise();
      const redirect = authResult.getRedirect();
      this.nzMessageService.success('Login successfully');
      this.router.navigateByUrl(redirect);
    } catch (error) {
      console.error(error, error.message, JSON.stringify(error));
      this.error = error.message || JSON.stringify(error);
      // console.log(`this.error`, this.error);
    }

    this.cd.markForCheck();
  }
  getOption(key: string): any {
    return getDeepFromObject(this.options || {}, key, null);
  }
}
