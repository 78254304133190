import { MessageService } from '../../../common/services/message.service';
import { EpoLoginService } from '../../../common/services/epo-login.service';
import { APP_CONSTANTS, ROUTE_CONTANTS } from '../../../common/constants/app.constants';
import { AuthHelper } from '../../../common/helpers/auth.helper';
import { ServiceHelper } from '../../../common/helpers/service.helper';
import { Inject, Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NbAuthService } from '../auth.service';
import { NB_AUTH_TOKEN_INTERCEPTOR_FILTER } from '../../auth.options';
import { AUTH_CONSTANTS } from '../../../common/constants/app.constants';
import { Router } from '@angular/router';
import { getMessage } from '../../../common/constants/error_code.constants';
import { LoginModel } from '../../../common/models/login.model';
import { LANG_VI, getApiErrorMessage } from '../../../../app.const';
import { AppLanguageService } from '../../../common/services/app-language.service';

@Injectable()
export class NbAuthJWTInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private router: Router,
    private messageService: MessageService,
    private loginService: NbAuthService,
    private appLanguageService: AppLanguageService,
    @Inject(NB_AUTH_TOKEN_INTERCEPTOR_FILTER) protected filter
  ) {}
  getErrKey(err: any) {
    return ServiceHelper.isObjectNotEmpty(err.error) && ServiceHelper.isObjectNotEmpty(err.error.errorKey) ? err.error.errorKey : null;
  }
  getErrMessage(error, errorKey, defaultMsg) {
    const errorMessage = error?.error?.message
      ? error.error.message
      : error.error && typeof error.error == 'string'
        ? error.error
        : error?.message && typeof error.message == 'string'
          ? error?.message
          : JSON.stringify(error.error || error);
    let message = errorKey && ServiceHelper.isObjectNotEmpty(errorKey) ? getMessage(errorKey) : errorMessage;
    if (!message) message = defaultMsg;
    if (!message) message = 'Unknow error';
    return message;
  }
  handleCacheActivityLog(req: HttpRequest<any>, event) {
    const method = req.method;
    const path = req.url;
    if (event instanceof HttpResponse) {
    }
    return event;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (ServiceHelper.isObjectNotEmpty(localStorage.getItem(AUTH_CONSTANTS.TOKEN))) {
      const JWT = `Bearer ${localStorage.getItem(AUTH_CONSTANTS.TOKEN)}`;
      req = req.clone({
        setHeaders: {
          'Access-Control-Allow-Origin': '*',
          Authorization: JWT,
          lang: localStorage.getItem(APP_CONSTANTS.LANGUAGE) || LANG_VI,
        },
      });
      return next.handle(req).pipe(
        map((event) => {
          return this.handleCacheActivityLog(req, event);
        }),
        catchError((error) => {
          console.error(error);
          let status: any = error?.status;
          if (status === 401 || (status === 500 && error.error.message === 'TOKEN_EXPIRE')) {
            this.loginService.requestRelogin();
            // Send the refreshtoken
            // this.loginService.refreshToken().subscribe(res => {
            //   AuthHelper.clearAllStorage();
            //   if (res.status === 200) {
            //     this.loginService.setLoginDataResponse(res.body);
            //     // Resend the api
            //     return next.handle(this.prepareRequestBeforeReSending(req));
            //   }

            // });
          } else if (status === 403) {
            let { message, key } = ServiceHelper.getApiErrorKeyMessage(error);
            message = message || this.appLanguageService.instantCustom('Bạn không có quyền thực hiện việc này');
            ServiceHelper.errorPopup(message);
            this.messageService.publishEvent({
              code: status,
              message: message,
            });
            this.router.navigateByUrl(ROUTE_CONTANTS.LOGIN);
          } else if (status === 400) {
            let { message, key } = ServiceHelper.getApiErrorKeyMessage(error);
            if (!message) message = getApiErrorMessage(error);
            this.messageService.publishEvent({
              code: status,
              message: message,
            });
            console.error(error);
            ServiceHelper.errorPopup(message);
          } else if (status === 500) {
            let { message, key } = ServiceHelper.getApiErrorKeyMessage(error);
            const errKey = 'be_internal_server_error';
            const errMessage = this.getErrMessage(error, errKey, 'Lỗi từ hệ thống, vui lòng thử lại sau');
            message = message || errMessage;
            ServiceHelper.errorPopup(message);
            this.messageService.publishEvent({
              code: status,
              message: message,
            });
          } else if (status === 404) {
            const errorKey = this.getErrKey(error);
            const errMessage = this.getErrMessage(error, errorKey, 'Không tìm thấy');
            let { message, key } = ServiceHelper.getApiErrorKeyMessage(error);
            this.messageService.publishEvent({
              code: status,
              message: message || errMessage,
            });
            ServiceHelper.errorPopup(message || errMessage);
          }
          return throwError(error);
        })
      );
    } else {
      return next.handle(req).pipe(
        catchError((error) => {
          console.error(error);
          let status = error?.status;
          if (status === 401) {
            this.loginService.forceLogout();
            ServiceHelper.errorPopup(this.appLanguageService.instantCustom('Vui lòng đăng nhập để sử dụng dịch vụ Eclaim'));
          }
          return throwError(error);
        })
      );
    }
  }

  // private prepareRequestBeforeReSending(req: HttpRequest<any>) {
  //   let header = req.headers;
  //   header = header.set('Authorization', `Bearer ${localStorage.getItem(AUTH_CONSTANTS.TOKEN)}`);
  //   header = header.set('Accept', 'application/json');
  //   header = header.set('Content-Type', 'application/json');
  //   return req.clone({
  //     headers: header
  //   });
  // }

  protected get authService(): NbAuthService {
    return this.injector.get(NbAuthService);
  }
}

// this.appLanguageService.instantCustom('Lỗi! Không thể thực hiện')
