import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, inject, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { NbDatepickerModule, NbDialogModule, NbMenuModule, NbSidebarModule, NbToastrModule, NbWindowModule } from '@nebular/theme';
import { NbAuthModule } from './pages/auth/auth.module';
import { NbPasswordAuthStrategy } from './pages/auth/strategies/password/password-strategy';
import { NbSecurityModule } from '@nebular/security';
import { AppLanguageModule } from './pages/common/components/app-language/app-language.module';
import { API_URL } from './pages/common/constants/api.constants';
import { NbAuthJWTInterceptor } from './pages/auth/services/interceptors/jwt-interceptor';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import localeVi from '@angular/common/locales/vi';
import { LANG_VI, blockUiRequestFilterOut } from './app.const';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BlockUIModule } from 'ng-block-ui';
import { BlockUIHttpModule } from 'ng-block-ui/http';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/** import all locales data **/
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import zh from '@angular/common/locales/zh';
registerLocaleData(en);
registerLocaleData(zh);

/** config ng-zorro-antd i18n **/
import { en_US, NZ_I18N, fr_FR } from 'ng-zorro-antd/i18n';
import { TextToTranslateKeyPipe } from './pages/pipes/textToTranslateKey';
import { NgxCurrencyModule, CurrencyMaskInputMode } from 'ngx-currency';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?dummy=' + Math.random().toString());
}
registerLocaleData(localeVi);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    AppLanguageModule,
    ThemeModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    CoreModule.forRoot(),
    NbSecurityModule.forRoot(),
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: 'email',
          token: {
            key: 'jwttoken', // this parameter tells where to look for the token
          },
          baseEndpoint: environment.apiBaseUrl + environment.defaultVersion,
          login: {
            endpoint: API_URL.LOGIN,
          },
        }),
      ],
      forms: {},
    }),
    NbDateFnsDateModule.forRoot({
      format: 'dd/MM/yyyy',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    BlockUIModule.forRoot(), // Import BlockUIModule
    // BlockUIHttpModule.forRoot(), // Import Block UI Http Module
    BlockUIHttpModule.forRoot({
      // * Accepts an array of requests to be filtered out from being blocked.
      requestFilters: blockUiRequestFilterOut,
    }),
    PdfViewerModule,
    NzBreadCrumbModule,
    NzRadioModule,
    NgxCurrencyModule.forRoot({
      align: 'right',
      allowNegative: true,
      allowZero: true,
      decimal: '.',
      precision: 0,
      prefix: '',
      suffix: '',
      thousands: ',',
      nullable: true,
      min: null,
      max: null,
      inputMode: CurrencyMaskInputMode.NATURAL,
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: NbAuthJWTInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: LANG_VI },
    {
      provide: NZ_I18N,
      useFactory: () => {
        const localId = inject(LOCALE_ID);
        switch (localId) {
          case 'en':
            return en_US;
          /** keep the same with angular.json/i18n/locales configuration **/
          case 'fr':
            return fr_FR;
          default:
            return en_US;
        }
      },
    },
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
