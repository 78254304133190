import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { NbAuthComponent, NbLoginComponent, NbLogoutComponent, NbRequestPasswordComponent, NbResetPasswordComponent } from './pages/auth/public_api';
import { AuthenticateGuard } from './pages/common/guards/authenticate.guard';
import { UnAuthenticateGuard } from './pages/common/guards/unauthenticate.guard';
import { TALENTNET_ROOT_ROUTE } from './pages/common/constants/app.constants';
import { TranslateModule } from '@ngx-translate/core';
import { NbLoginCallbackComponent } from './pages/auth/components/logincallback/logincallback.component';

const routes: Routes = [
  {
    path: TALENTNET_ROOT_ROUTE,
    loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
    canActivate: [AuthenticateGuard],
  },
  {
    path: 'auth/login',
    component: NbLoginComponent,
    canActivate: [UnAuthenticateGuard],
  },
  {
    path: 'auth/login/callback',
    component: NbLoginCallbackComponent,
    // canActivate: [UnAuthenticateGuard],
  },
  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: NbLoginComponent,
      },
      // {
      //   path: 'login',
      //   component: NbLoginComponent,
      //   canActivate: [UnAuthenticateGuard]
      // },
      {
        path: 'logout',
        component: NbLogoutComponent,
      },
      {
        path: 'request-password',
        component: NbRequestPasswordComponent,
      },
      {
        path: 'reset-password',
        component: NbResetPasswordComponent,
      },
    ],
  },
  { path: '', redirectTo: 'app', pathMatch: 'full' },
  { path: '**', redirectTo: 'app' },
];

const config: ExtraOptions = {
  useHash: false,
  onSameUrlNavigation: 'reload',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config), TranslateModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
