import { EpoLoginService } from '../../../pages/common/services/epo-login.service';
import { AUTH_CONSTANTS } from '../../../pages/common/constants/app.constants';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbContextMenuContext, NbMenuItem, NbMenuService, NbSidebarService } from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { Subject } from 'rxjs';
import { AppLanguageService } from '../../../pages/common/services/app-language.service';
import { ROUTE_CONTANTS } from '../../../pages/common/constants/app.constants';
import { DatePipe } from '@angular/common';
import { AuthHelper } from '../../../pages/common/helpers/auth.helper';
import { Router } from '@angular/router';
import { ServiceHelper } from '../../../pages/common/helpers/service.helper';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  providers: [DatePipe],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  currentTheme = 'default';
  userMenu: NbMenuItem[] = [];

  expiredTime: string = localStorage.getItem(AUTH_CONSTANTS.TOKEN_EXPIRED);
  FIVE_MINUTES: number = 300_000;
  TEN_MINUTES: number = 600_000;
  myDate = new Date();
  currentDate: String;

  widgetInfo: any = {};
  chatAiMessageTooltip = '';
  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private loginService: EpoLoginService,
    private appLanguageService: AppLanguageService,
    private layoutService: LayoutService,
    protected router: Router,
    private datePipe: DatePipe
  ) {
    this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'profile-context-menu'),
        map((event) => event)
      )
      .subscribe((event) => {
        if (event?.item?.data && event?.item?.data['key'] == 'LOGOUT') {
          this.logout();
        }
      });
    this.currentDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    if (this.appLanguageService.getLanguage()) {
      this.appLanguageService.use(this.appLanguageService.getLanguage());
    }

    this.configUserMenu();

    this.appLanguageService.onLangChange.subscribe((lang) => {
      this.appLanguageService.use(lang.lang);
      this.configUserMenu();
    });
  }
  logout() {
    AuthHelper.clearAllStorage();
    this.router.navigateByUrl(ROUTE_CONTANTS.LOGIN);
  }

  configUserMenu() {
    this.userMenu = [
      {
        title: this.appLanguageService.instant('HEADER_PAGE.LOGOUT'),
        data: {
          key: 'LOGOUT',
        },
        icon: { icon: 'log-out-black-01', pack: 'eclaim' },
        // link: ROUTE_CONTANTS.LOGOUT
      },
    ];
  }

  ngOnInit() {
    this.user = {
      name: localStorage.getItem(AUTH_CONSTANTS.EPO_USER_FULLNAME),
      role: localStorage.getItem(AUTH_CONSTANTS.EPO_USER_ID),
    };

    // const expired = moment(this.expiredTime, 'DD-MM-YYYY HH:mm:ss');
    // setInterval(() => {
    //   if (ServiceHelper.isObjectNotEmpty(localStorage.getItem(AUTH_CONSTANTS.TOKEN))) {
    //     const diffInMins: number = expired.diff(moment(new Date()), 'minutes');
    //     if (diffInMins > 0 && diffInMins <= 10) {
    //     }
    //     if (diffInMins <= 0) {
    //       this.loginService.requestRelogin();
    //     }
    //   }
    // }, this.FIVE_MINUTES);
    this.widgetInfo = this.getChatAiInfo();
    this.chatAiMessageTooltip = (this.widgetInfo as any).widgetMessage;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
  getChatAiInfo() {
    let data = localStorage.getItem('chatai-widget-info');
    if (data) {
      try {
        data = JSON.parse(data);
        return data;
      } catch (error) {
        console.error(error);
        return {};
      }
    }
    return {};
  }
  openChatAi() {
    let isOpen = (window['ChatAIWidgetObject'] as any).isOpenChatBox;
    if (isOpen === false) {
      window['ChatAIWidgetObject'] && window['ChatAIWidgetObject'].show && window['ChatAIWidgetObject'].show();
      (window['ChatAIWidgetObject'] as any).isOpenChatBox = true;
    } else {
      window['ChatAIWidgetObject'] && window['ChatAIWidgetObject'].hide && window['ChatAIWidgetObject'].hide();
      (window['ChatAIWidgetObject'] as any).isOpenChatBox = false;
    }
  }
}
