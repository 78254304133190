import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONSTANTS } from '../constants/app.constants';
import { LANG_EN, LANG_VI } from '../../../app.const';
import { TextToTranslateKeyPipe } from '../../pipes/textToTranslateKey';

@Injectable({
  providedIn: 'root',
})
export class AppLanguageService extends TranslateService {
  private supportedLanguages: string[] = [LANG_VI, LANG_EN];
  private language: string;

  public getLanguage(): string {
    const language = localStorage.getItem(APP_CONSTANTS.LANGUAGE);
    return this.isSupportedLanguage(language) ? language : LANG_VI;
  }

  private isSupportedLanguage(language: string): boolean {
    return this.supportedLanguages.includes(language);
  }

  public setLanguage(language): void {
    if (this.isSupportedLanguage(language)) {
      localStorage.setItem(APP_CONSTANTS.LANGUAGE, language);
      return;
    }
    console.error('Language ' + language + ' is not supported, we will set english as default');
  }
  public instantCustom(text) {
    let key = text;
    let pipeTextToKey = new TextToTranslateKeyPipe();
    key = pipeTextToKey.transform(text);
    if (!key) return '';
    return this.instant(key || '');
  }
}
