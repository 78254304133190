<nb-layout>
  <nb-layout-column style="padding: 0">
    <div class="login-page">
      <div class="login-container">
        <div class="login-container-header">
          <div class="row">
            <div class="col-6">
              <img src="assets/images/logo/Logo-horz.svg" class="logo-icon" />
            </div>
            <div class="col-6 text-right right-block">
              <span class="dont-have-account">{{ 'LOGIN_PAGE.DONT_HAVE_ACCOUNT' | translate }}</span>
              <span class="contact-admin">
                {{ 'LOGIN_PAGE.CONTACT_ADMIN' | translate }}
              </span>
            </div>
          </div>
        </div>
        <div class="login-container-body">
          <div class="text-center d-xs-block d-sm-block d-md-none d-lg-none">
            <img src="assets/images/logo-round.png" class="login-logo" style="width: 60px; height: 60px" />
          </div>
          <h1>{{ 'LOGIN_PAGE.PAGE_TITLE' | translate }}</h1>
          <button class="btn btn-primary btn-login-eclaim" (click)="clickLoginAzure()">
            <img src="assets/images/azure.png" class="azure" />
            {{ 'LOGIN_PAGE.BTN_SIGN_IN_AZURE' | translate }}
          </button>
          <div class="or">
            <span>{{ 'LOGIN_PAGE.OR' | translate }}</span>
          </div>
          <div class="enter-credential">
            {{ 'LOGIN_PAGE.ENTER_CREDENTIAL' | translate }}
          </div>

          <form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">
            <div class="form-control-group text-left">
              <label class="label-input-email" for="input-email">
                {{ 'LOGIN_PAGE.USERNAME' | translate }}
              </label>
              <input
                nbInput
                fullWidth
                [(ngModel)]="user.username"
                #email="ngModel"
                name="email"
                id="input-email"
                pattern=".+"
                placeholder="{{ 'LOGIN_PAGE.USERNAME' | translate }}"
                fieldSize="large"
                autofocus
                [status]="email.dirty ? (email.invalid ? 'danger' : 'success') : 'basic'"
                [required]="getConfigValue('forms.validation.email.required')"
                [attr.aria-invalid]="email.invalid && email.touched ? true : null"
              />
              <ng-container *ngIf="email.invalid && email.touched">
                <p class="caption status-danger" *ngIf="email.errors?.required">{{ 'LOGIN_PAGE.FILL_USERNAME' | translate }}</p>
                <p class="caption status-danger" *ngIf="email.errors?.pattern">{{ 'LOGIN_PAGE.INVALID_USERNAME' | translate }}</p>
              </ng-container>
            </div>
            <div class="form-control-group text-left">
              <span class="label-with-link">
                <label class="label-input-password" for="input-password">
                  {{ 'LOGIN_PAGE.PASSWORD' | translate }}
                </label>
              </span>
              <input
                nbInput
                fullWidth
                [(ngModel)]="user.password"
                #password="ngModel"
                name="password"
                type="password"
                id="input-password"
                placeholder="{{ 'LOGIN_PAGE.PASSWORD' | translate }}"
                fieldSize="large"
                [status]="password.dirty ? (password.invalid ? 'danger' : 'success') : 'basic'"
                [required]="getConfigValue('forms.validation.password.required')"
                [minlength]="getConfigValue('forms.validation.password.minLength')"
                [maxlength]="getConfigValue('forms.validation.password.maxLength')"
                [attr.aria-invalid]="password.invalid && password.touched ? true : null"
              />
              <ng-container *ngIf="password.invalid && password.touched">
                <p class="caption status-danger" *ngIf="password.errors?.required">
                  {{ 'LOGIN_PAGE.FILL_PASSWORD' | translate }}
                </p>
                <p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">
                  {{ 'LOGIN_PAGE.INVALID_PASSWORD' | translate }}
                </p>
              </ng-container>
            </div>
            <div class="form-control-group text-left group-forget-password">
              <div class="row">
                <div class="col-6 text-left">
                  <span class="check-remember">
                    <input type="checkbox" />
                    <span class="remember-30-day">{{ 'LOGIN_PAGE.REMEMBER_30DAY' | translate }}</span>
                  </span>
                </div>
                <div class="col-6 text-right">
                  <a class="forgot-password-2 caption-2" routerLink="../request-password">
                    {{ 'LOGIN_PAGE.FORGOT_PASSWORD' | translate }}
                  </a>
                </div>
              </div>
            </div>

            <p *ngIf="loginFailed" class="caption status-danger" style="font-size: medium">
              {{ 'LOGIN_PAGE.INVALID_USERNAME_PASSWORD' | translate }}
            </p>

            <button nbButton class="btn-signin" fullWidth size="large" [nbSpinner]="submitted" nbSpinnerStatus="control" [disabled]="submitted">
              {{ 'LOGIN_PAGE.BTN_SIGN_IN' | translate }}
            </button>
          </form>
        </div>
        <div class="login-container-footer">
          <div class="row">
            <div class="col-12">
              <div class="text-center" style="margin-top: 10px">
                <span class="dont-have-account">{{ 'LOGIN_PAGE.DONT_HAVE_ACCOUNT' | translate }}</span>
                <span class="contact-admin">
                  {{ 'LOGIN_PAGE.CONTACT_ADMIN' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <nb-card>
      <nb-card-body>
        <nb-auth-block>
          <h1 id="title" class="title">
            <img class="logo-icon" src="assets/images/logo-top.png" alt="logo" style="width: 160px; height: auto; margin-top: 30px; margin-bottom: 30px;" />
          </h1>

          <form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">
            <div class="form-control-group">
              <label class="label" for="input-email">Tên tài khoản:</label>
              <input
                nbInput
                fullWidth
                [(ngModel)]="user.username"
                #email="ngModel"
                name="email"
                id="input-email"
                pattern=".+"
                placeholder="Tên tài khoản"
                fieldSize="large"
                autofocus
                [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
                [required]="getConfigValue('forms.validation.email.required')"
                [attr.aria-invalid]="email.invalid && email.touched ? true : null"
              />
              <ng-container *ngIf="email.invalid && email.touched">
                <p class="caption status-danger" *ngIf="email.errors?.required">Vui lòng điền tên tài khoản!</p>
                <p class="caption status-danger" *ngIf="email.errors?.pattern">Tên tài khoản không hợp lệ</p>
              </ng-container>
            </div>
            <div class="form-control-group">
              <span class="label-with-link">
                <label class="label" for="input-password">Mật khẩu:</label>
                <a class="forgot-password caption-2" routerLink="../request-password">Quên mật khẩu?</a>
              </span>
              <input
                nbInput
                fullWidth
                [(ngModel)]="user.password"
                #password="ngModel"
                name="password"
                type="password"
                id="input-password"
                placeholder="Mật khẩu"
                fieldSize="large"
                [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
                [required]="getConfigValue('forms.validation.password.required')"
                [minlength]="getConfigValue('forms.validation.password.minLength')"
                [maxlength]="getConfigValue('forms.validation.password.maxLength')"
                [attr.aria-invalid]="password.invalid && password.touched ? true : null"
              />
              <ng-container *ngIf="password.invalid && password.touched ">
                <p class="caption status-danger" *ngIf="password.errors?.required">Vui lòng điền mật khẩu!</p>
                <p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">Mật khẩu không hợp lệ</p>
              </ng-container>
            </div>
            <p *ngIf="loginFailed" class="caption status-danger" style="font-size: medium;">Tên đăng nhập hoặc mật khẩu không đúng</p>
            <button nbButton class="btn-login" fullWidth size="large" [nbSpinner]="submitted" nbSpinnerStatus="control" [disabled]="submitted">Đăng nhập</button>
          </form>
        </nb-auth-block>
      </nb-card-body>
    </nb-card> -->
  </nb-layout-column>
</nb-layout>
