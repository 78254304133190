import { environment } from '../../../../environments/environment';
export const API_URL = {
  LOGIN: '/auth/login',
  REFRESH_TOKEN: `${environment.apiBaseUrl}${environment.defaultVersion}/auth/refresh-token`,
  CHECK_TOKEN_VALID: `${environment.apiSsoUrl}/api/org/me`,
  ROLE_CONFIG: {
    SEARCH: environment.apiBaseUrl + environment.defaultVersion + '/userRole/search?',
    UPDATE: environment.apiBaseUrl + environment.defaultVersion + '/userRole/update',
  },
  PO: {
    ROOT: `${environment.apiBaseUrl}${environment.defaultVersion}/po`,
    CREATE_PO_DETAIL: `${environment.apiBaseUrl}${environment.defaultVersion}/po/create-detail`,
    GET_CREATOR_PURCHASE_ORDERS: `${environment.apiBaseUrl}${environment.defaultVersion}/po/dropdown`,
  },
  SUPPLIER: {
    ROOT: `${environment.apiBaseUrl}${environment.defaultVersion}/supplier`,
  },
  BUDGET_CONTROL: {
    BUDGET_REQUEST_ROOT: environment.apiBaseUrl + environment.defaultVersion + '/budget-request',
    BUDGET_ROOT: environment.apiBaseUrl + environment.defaultVersion + '/budget',
  },
  COMMON_DATA: {
    GET_ALL_CURRENCY: `${environment.apiBaseUrl}${environment.defaultVersion}/currency`,
    GET_ALL_OSS_DEPART: `${environment.apiBaseUrl}${environment.defaultVersion}/oss-department`,
    GET_ALL_OSS_COSTS: `${environment.apiBaseUrl}${environment.defaultVersion}/oss-costs`,
    GET_ALL_OSS_BANK: `${environment.apiBaseUrl}${environment.defaultVersion}/oss-bank`,
    GET_ALL_OSS_PAYMENT_SUBJECT: `${environment.apiBaseUrl}${environment.defaultVersion}/oss-payment-subject`,
    GET_EXPENSE_ITEM_BY_BUDGET_CODE: `${environment.apiBaseUrl}${environment.defaultVersion}/expense-item/budget`,
    GET_EXPENSE_ITEM_BY_TYPE: `${environment.apiBaseUrl}${environment.defaultVersion}/expense-item/budgetItem`,
    GET_ALL_TAX_TYPE: `${environment.apiBaseUrl}${environment.defaultVersion}/tax`,
    GET_ALL_CONTROL_DEPARTMENT: `${environment.apiBaseUrl}${environment.defaultVersion}/control-depart`,
    GET_ALL_CONTROL_DEPARTMENT_V2: `${environment.apiBaseUrl}${environment.defaultVersion}/dgs/v2/control-depart`,
    GET_ALL_SPONSOR_DEPARTMENT: `${environment.apiBaseUrl}${environment.defaultVersion}/sponsor-depart`,
    GET_ALL_SPONSOR_DEPARTMENT_V2: `${environment.apiBaseUrl}${environment.defaultVersion}/dgs/v2/sponsor-depart`,
    SEARCH_APPROVER_BY_NAME: `${environment.apiBaseUrl}${environment.defaultVersion}/approver`,
    SEARCH_APPROVER_BY_NAME_LEVEL: `${environment.apiBaseUrl}${environment.defaultVersion}/approver/level`,
    SEARCH_OSS_APPROVER_BY_NAME: `${environment.apiBaseUrl}${environment.defaultVersion}/oss-approver`,
    SEARCH_USER_BY_NAME: `${environment.apiBaseUrl}${environment.defaultVersion}/user-tln`,
    SEARCH_USER_OSS_BY_NAME: `${environment.apiBaseUrl}${environment.defaultVersion}/user-oss`,
    SEARCH_CUSTOMER_BY_NAME: `${environment.apiBaseUrl}${environment.defaultVersion}/customer`,
    SEARCH_BUDGET_BY_CODE: `${environment.apiBaseUrl}${environment.defaultVersion}/budget`,
    SEARCH_MATERIAL_BY_CODE: `${environment.apiBaseUrl}${environment.defaultVersion}/material`,
    SEARCH_EXPENSE_ITEM_BY_CODE: `${environment.apiBaseUrl}${environment.defaultVersion}/expense-item`,
    SEARCH_ACCOUNTANT_DEPART_BY_CODE: `${environment.apiBaseUrl}${environment.defaultVersion}/accountant-depart`,
    SEARCH_ACCOUNTANT_DEPART_V2: `${environment.apiBaseUrl}${environment.defaultVersion}/dgs/v2/accountant-depart`,
    SEARCH_ADVANCE_PAYMENT: `${environment.apiBaseUrl}${environment.defaultVersion}/advance-payments/search-support`,
    SEARCH_ADVANCE_PAYMENT_OSS: `${environment.apiBaseUrl}${environment.defaultVersion}/advance-payments-oss/search-support`,
    GET_AMIN_PERMISSION: `${environment.apiBaseUrl}${environment.defaultVersion}/admin`,
    SEARCH_BUDGET_REQUEST_BY_CODE: `${environment.apiBaseUrl}${environment.defaultVersion}/budget-request/search`,
    SEARCH_PO_BY_ID: `${environment.apiBaseUrl}${environment.defaultVersion}/po/search`,
    SEARCH_EX_BY_ID: `${environment.apiBaseUrl}${environment.defaultVersion}/expense/search-support`,
    GET_ALL_BUDGET_ITEM: `${environment.apiBaseUrl}${environment.defaultVersion}/budget-item`,
    GET_LOCATION: `${environment.apiBaseUrl}${environment.defaultVersion}/locations`,
    GET_EXPENSE_ITEM_BY_USER_ID: `${environment.apiBaseUrl}${environment.defaultVersion}/expense-item/budgetItem/customer`,
    GET_BENEFICIARY: `${environment.apiBaseUrl}${environment.defaultVersion}/user-tln/beneficiary`,
    SEARCH_OSS_CODE: `${environment.apiBaseUrl}${environment.defaultVersion}/bank-transfer/documents`,
    GET_UNIT_OF_MEASURE: `${environment.apiBaseUrl}${environment.defaultVersion}/unit-of-measures`,
    GET_ACTIVITY_LOG: `${environment.apiBaseUrl}${environment.defaultVersion}/dgs/v2/logs`,
    GET_ACTIVITY_LOG_ENUM_CMD: `${environment.apiBaseUrl}${environment.defaultVersion}/dgs/v2/logs/commands`,
  },
  EXTRACT_CSV: {
    ROOT: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/extract`,
  },
  EXTRACT_EXCEL: {
    ROOT: `${environment.apiBaseUrl}${environment.defaultVersion}/excel/extract`,
  },
  IMPORT_CSV: {
    IMPORT_BUDGET: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/import/budget`,
    IMPORT_APPROVER: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/import/approver`,
    IMPORT_USER: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/import/user`,
    IMPORT_CURRENCY: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/import/currency`,
    IMPORT_ACCOUNTANT_DEPART: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/import/accountant-depart`,
    IMPORT_CONTROL_DEPART: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/import/control-depart`,
    IMPORT_CUSTOMER: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/import/customer`,
    IMPORT_EXPENSE_ITEM: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/import/expense-item`,
    IMPORT_MATERIAL: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/import/material`,
    IMPORT_SPONSOR_DEPART: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/import/sponsor-depart`,
    IMPORT_USER_OSS: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/import/user-oss`,
    IMPORT_TAX: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/import/tax`,
    IMPORT_SUPPLIER: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/import/supplier`,
    IMPORT_BUDGET_ITEM: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/import/budget-item`,
    IMPORT_BUDGET_ITEM_DETAIL: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/import/budget-item-detail`,
    IMPORT_OSS_BANK: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/import/oss-bank`,
    IMPORT_OSS_COSTS: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/import/oss-costs`,
    IMPORT_OSS_DEPARTMENT: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/import/oss-department`,
    IMPORT_OSS_PAYMENT_SUBJECT: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/import/oss-payment-subject`,
    IMPORT_ADVANCE_LIMIT: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/import/oss-advance-limit`,
    IMPORT_HOLIDAY: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/import/holiday`,
    IMPORT_LOCATION: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/import/location`,
    IMPORT_UNIT_OF_MEASURE: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/import/unit-of-measure`,
  },
  EXPORT_CSV: {
    EXPORT_BUDGET: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/export/budget`,
    EXPORT_APPROVER: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/export/approver`,
    EXPORT_USER: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/export/user`,
    EXPORT_CURRENCY: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/export/currency`,
    EXPORT_ACCOUNTANT_DEPART: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/export/accountant-depart`,
    EXPORT_CONTROL_DEPART: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/export/control-depart`,
    EXPORT_CUSTOMER: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/export/customer`,
    EXPORT_EXPENSE_ITEM: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/export/expense-item`,
    EXPORT_MATERIAL: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/export/material`,
    EXPORT_SPONSOR_DEPART: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/export/sponsor-depart`,
    EXPORT_USER_OSS: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/export/user-oss`,
    EXPORT_TAX: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/export/tax`,
    EXPORT_SUPPLIER: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/export/supplier`,
    EXPORT_BUDGET_ITEM: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/export/budget-item`,
    EXPORT_BUDGET_ITEM_DETAIL: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/export/budget-item-detail`,
    EXPORT_OSS_BANK: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/export/oss-bank`,
    EXPORT_OSS_COSTS: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/export/oss-costs`,
    EXPORT_OSS_DEPARTMENT: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/export/oss-department`,
    EXPORT_OSS_PAYMENT_SUBJECT: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/export/oss-payment-subject`,
    EXPORT_ADVANCE_LIMIT: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/export/oss-advance-limit`,
    EXPORT_HOLIDAY: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/export/holiday`,
    EXPORT_LOCATION: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/export/location`,
    EXPORT_UNIT_OF_MEASURE: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/export/unit-of-measure`,
    EXPORT_LOG: `${environment.apiBaseUrl}${environment.defaultVersion}/admin/logs`,
    EXPORT_EXCEPTION_LOG: `${environment.apiBaseUrl}${environment.defaultVersion}/admin/exception-logs`,
  },
  EXPORT_EXCEL: {
    EXPORT_BUDGET: `${environment.apiBaseUrl}${environment.defaultVersion}/excel/export/budget`,
    EXPORT_APPROVER: `${environment.apiBaseUrl}${environment.defaultVersion}/excel/export/approver`,
    EXPORT_USER: `${environment.apiBaseUrl}${environment.defaultVersion}/excel/export/user`,
    EXPORT_CURRENCY: `${environment.apiBaseUrl}${environment.defaultVersion}/excel/export/currency`,
    EXPORT_ACCOUNTANT_DEPART: `${environment.apiBaseUrl}${environment.defaultVersion}/excel/export/accountant-depart`,
    EXPORT_CONTROL_DEPART: `${environment.apiBaseUrl}${environment.defaultVersion}/excel/export/control-depart`,
    EXPORT_CUSTOMER: `${environment.apiBaseUrl}${environment.defaultVersion}/excel/export/customer`,
    EXPORT_EXPENSE_ITEM: `${environment.apiBaseUrl}${environment.defaultVersion}/excel/export/expense-item`,
    EXPORT_MATERIAL: `${environment.apiBaseUrl}${environment.defaultVersion}/excel/export/material`,
    EXPORT_SPONSOR_DEPART: `${environment.apiBaseUrl}${environment.defaultVersion}/excel/export/sponsor-depart`,
    EXPORT_USER_OSS: `${environment.apiBaseUrl}${environment.defaultVersion}/excel/export/user-oss`,
    EXPORT_TAX: `${environment.apiBaseUrl}${environment.defaultVersion}/excel/export/tax`,
    EXPORT_SUPPLIER: `${environment.apiBaseUrl}${environment.defaultVersion}/excel/export/supplier`,
    EXPORT_BUDGET_ITEM: `${environment.apiBaseUrl}${environment.defaultVersion}/excel/export/budget-item`,
    EXPORT_BUDGET_ITEM_DETAIL: `${environment.apiBaseUrl}${environment.defaultVersion}/excel/export/budget-item-detail`,
    EXPORT_OSS_BANK: `${environment.apiBaseUrl}${environment.defaultVersion}/excel/export/oss-bank`,
    EXPORT_OSS_COSTS: `${environment.apiBaseUrl}${environment.defaultVersion}/excel/export/oss-costs`,
    EXPORT_OSS_DEPARTMENT: `${environment.apiBaseUrl}${environment.defaultVersion}/excel/export/oss-department`,
    EXPORT_OSS_PAYMENT_SUBJECT: `${environment.apiBaseUrl}${environment.defaultVersion}/excel/export/oss-payment-subject`,
    EXPORT_ADVANCE_LIMIT: `${environment.apiBaseUrl}${environment.defaultVersion}/excel/export/oss-advance-limit`,
    EXPORT_MASTER_FILE: `${environment.apiBaseUrl}${environment.defaultVersion}/excel/export/master-data`,
    EXPORT_REVENUE: `${environment.apiBaseUrl}${environment.defaultVersion}/excel/export/revenue`,
    EXPORT_SOA_FILE: `${environment.apiBaseUrl}${environment.defaultVersion}/excel/export/soa-data`,
  },
  IMPORT_EXCEL: {
    ROOT: `${environment.apiBaseUrl}${environment.defaultVersion}/excel/import`,
  },
  DOWNLOAD_CSV: {
    ROOT: `${environment.apiBaseUrl}${environment.defaultVersion}/csv/download`,
  },
  AD: {
    ROOT: `${environment.apiBaseUrl}${environment.defaultVersion}/advance-payments`,
    OSS: {
      ROOT: `${environment.apiBaseUrl}${environment.defaultVersion}/advance-payments-oss`,
    },
  },
  AD_LIMIT_OSS: {
    ROOT: `${environment.apiBaseUrl}${environment.defaultVersion}/oss-advance-limit`,
    LIST: `${environment.apiBaseUrl}${environment.defaultVersion}/oss-advance-limit/list`,
  },
  OSS_LIMIT_REVISE: {
    ROOT: `${environment.apiBaseUrl}${environment.defaultVersion}/limit-revise-oss`,
  },
  OSS_EXPENSE: {
    ROOT: `${environment.apiBaseUrl}${environment.defaultVersion}/oss-expense`,
  },
  OSS_AD: {
    ROOT: `${environment.apiBaseUrl}${environment.defaultVersion}/advance-payments-oss`,
  },
  USER: {
    ROOT: `${environment.apiBaseUrl}${environment.defaultVersion}/user-tln`,
  },
  CONTRACT: {
    ROOT: `${environment.apiBaseUrl}${environment.defaultVersion}/contract`,
  },
  EXPENSE: {
    ROOT: `${environment.apiBaseUrl}${environment.defaultVersion}/expense`,
  },
  ADVANCE_REIMBURSEMENT: {
    ROOT: `${environment.apiBaseUrl}${environment.defaultVersion}/reimbursement`,
  },
  DOCUMENT_CATEGORY: {
    ROOT: `${environment.apiBaseUrl}${environment.defaultVersion}/document-category`,
  },
  INVOICE: {
    ROOT: `${environment.apiBaseUrl}${environment.defaultVersion}/invoices`,
  },
  BANKTRANSFER: {
    ROOT: `${environment.apiBaseUrl}${environment.defaultVersion}/oss-bank-transfer`,
  },
  DASHBOARD: {
    ROOT: `${environment.apiBaseUrl}${environment.defaultVersion}/dashboard`,
  },
  INTERNAL_REPORT: {
    ROOT: `${environment.apiBaseUrl}${environment.defaultVersion}/report`,
  },
  OSS_REPORT: {
    ROOT: `${environment.apiBaseUrl}${environment.defaultVersion}/oss-report`,
  },
  INTEGRATION_LOG: {
    ROOT: `${environment.apiBaseUrl}${environment.defaultVersion}/integration-logs`,
  },
  BANKTRANSFER_V2: {
    ROOT: `${environment.apiBaseUrl}${environment.defaultVersion}/bank-transfer`,
  },
  VIDEO: `${environment.apiBaseUrl}${environment.defaultVersion}/video`,
  REVENUE_SHARING: `${environment.apiBaseUrl}${environment.defaultVersion}/revenue-sharing`,
  EMAIL: `${environment.apiBaseUrl}${environment.defaultVersion}/email`,
  BIZZI: {
    INVOICE: `${environment.apiBaseUrl}${environment.defaultVersion}/bizzi/invoices`,
  },
  SCHEDULED_TASKS: {
    ROOT: `${environment.apiBaseUrl}${environment.defaultVersion}/scheduled-tasks`,
  },
};
